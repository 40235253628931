<template>
    <div>
        <!-- 时间 -->
        <div class="eff-time">
            <div style="font-weight:700;font-size:16px;color:black;margin-bottom:10px">已提交预约</div>
            {{data.start_time}}至 {{data.end_time}}可用
        </div>
        <!-- 信息 -->
        <div class="goods-info">

            <div class="ewm">
                <div>
                    <div>扫码添加康养顾问</div>
                    <div>一对一安排检查进程</div>
                    <van-image :src=" data.kf_info.kf_qrcode || 'https://khome2.tuzuu.com/klfront/kefu.png'" width="100" height="100"/>
                    <van-button round type="info" v-if="data.has_course == 1"   @click="hrefCoures">免费观看康养课程</van-button>
                </div>

            </div>

            <div class="goods-title">
                {{data.title}}
            </div>

            <div class="flex-item">
                <div class="small-title">
                    检查内容
                </div>
                <div v-html="data.check_items" style="font-size:12px">
                   
                </div>
            </div>


            <div class="rule">
                <div class="flex-item">
                    <div class="small-title">
                        使用规则
                    </div>
                    <div v-html="data.buy_rules" style="font-size:12px">
                      
                    </div>
                </div>
                <!-- <div class="flex-item">
                    <div class="small-title">
                        退换规则
                    </div>
                    <div>
                        少时诵诗书所所所所所所所所所所所所所所所所所所所所所所所所所少时诵诗书所所所所所所所所所所所所所所所所所所所所所所所所所
                    </div>
                </div> -->
            </div>

            <div class="flex-item">
                <div class="small-title">
                    订单信息
                </div>
                <div class="rule">
                    <div class="goods-info-item">
                        <div class="item-title">已购商品</div>
                        <div>{{data.title}}</div>
                    </div>
                    <div class="goods-info-item">
                        <div class="item-title">订单号</div>
                        <div>{{data.order.sn}}</div>
                    </div>
                     <div class="goods-info-item">
                         <div class="item-title">下单时间</div>
                         <div>{{data.start_time}}</div>
                    </div>
                    <div class="goods-info-item">
                         <div class="item-title">购买份数</div>
                         <div>x{{data.order.buy_num}}</div>
                    </div>
                    <div class="goods-info-item">
                         <div class="item-title">支付金额</div>
                         <div>¥{{data.order.price}}</div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data(){
        return{
            data:{
                order:{sn:''},
                kf_info:{kf_qrcode:''}
            },
        }
    },
    created(){
        this.data = this.$store.state.order_success
        if(this.data.length <= 0){
            this.$router.push({path:'/home'})
        }
        console.log(this.data)
        // let data = window.sessionStorage.getItem('order_success')
       
    },
    methods:{
        hrefCoures(){
              window._czc.push(['_trackEvent','预约成功页', '查看课程'])
            window.sessionStorage.setItem("is_couese",'true')
            this.$router.push({path:'/course'})
        },
    }
}
</script>

<style lang="less" scoped>
    .eff-time{
        text-align: center;
        margin-top: 10px;
        color: #c3b5b5;
    }
    .goods-info{
        padding: 20px 15px;
        box-sizing: border-box;
    }
    .goods-title{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 12px;
    }
    .flex-item{
        margin-bottom: 20px;
        word-break:break-all;
        .small-title{
            color: #c3b5b5;
            font-size: 13px;
            margin-bottom: 10px;
        }
    }
    .code{
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        /deep/ .van-button--mini{
            width: 68px;
        }
    }

    .rule{
        background: #FBFBFB;
        padding: 17px 17px;
        box-sizing: border-box;
        border-radius: 5px;
        font-size: 12px;
        margin-bottom: 20px;
    }
    .goods-info-item{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 12px;
        .item-title{
            font-weight: 700;
            white-space: nowrap;
            margin-right: 45px;
        }
    }
    .ewm{
        >div{
            width: fit-content;
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            font-size: 12px;
            align-items:center;
            margin-bottom: 20px;
            >div{
                margin-bottom: 5px;
            }
            /deep/ .van-button--normal{
                padding: 0px 40px;
            }
        }
    }
</style>